.searchbar {
  margin-top: 140px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 20px;
  border-radius: 5px;
}

.optiontitle {
  width: 80px;
  font-weight: bold;
}
.optiontitle1 {
  width: 140px;
  font-weight: bold;
}
.optiontitle2 {
  width: 140px;
  font-weight: bold;
}
.optioninfo {
  font-weight: 500;
}
.marquee {
  /* width: 450px; */
  line-height: 50px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 55s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.text-content {
  line-height: 2em;
  color: black;
  font-weight: 500;
  text-align: justify;
}
.section-content {
  background-color: white;
  /* margin-top: -80px; */
  z-index: 999;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 20px;
  /* position: absolute; */
  /* left: 50%; */
  /* margin-left: -10px; */
  /* top: 40%; */
  margin-top: -40px;
  /* transform: translate(-50%, -50%); */
}

.info-horizontal {
  text-align: left;
  margin-top: 0;
}
.info {
  padding: 30px 0;
  max-width: 400px;
  margin: 0 auto;
}

.p_wrap {
  white-space: pre-line;
}

.column-wrap {
  column-count: 3;
  column-fill: auto;
}
.column-wrap > li {
  display: block;
}
.column-wrap > li:before {
  content: "";
  display: list-item;
  float: left;
  position: absolute;
  padding-right: 30px;
}

@import "~react-image-gallery/styles/css/image-gallery.css";
